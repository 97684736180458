import React, { useState, useEffect } from "react";
import Image1 from "../../assets/class room/1.jpg";
import Image2 from "../../assets/class room/2.jpg";
import Image3 from "../../assets/class room/3.jpg";
import Image4 from "../../assets/class room/4.jpg";
import Image5 from "../../assets/class room/5.jpg";
import Image6 from "../../assets/class room/6.jpg";
import Image7 from "../../assets/class room/7.jpg";
import { GrPrevious, GrNext } from "react-icons/gr";


const images = [Image2, Image1, Image3, Image4, Image5, Image6,Image7];

const Hero = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const goToPreviousSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    const goToNextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    return (
        <div className="relative h-screen">
            <button
                className="absolute top-1/2 left-4 z-10 transform -translate-y-1/2 bg-gray-800 text-white px-3 py-2 rounded-full focus:outline-none"
                onClick={goToPreviousSlide}
            >
                <GrPrevious />
            </button>
            {images.map((image, index) => (
                <img
                    key={index}
                    src={image}
                    alt={`Slide ${index}`}
                    className={`absolute top-0 left-0 w-full h-full transition-opacity duration-500 ${index === currentIndex ? "opacity-100" : "opacity-0"
                        }`}
                />
            ))}
            <button
                className="absolute top-1/2 right-4 z-10 transform -translate-y-1/2 bg-gray-800 text-white px-3 py-2 rounded-full focus:outline-none"
                onClick={goToNextSlide}
            >
                <GrNext />
            </button>
        </div>
    );
};

export default Hero;
