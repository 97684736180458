import { FaChartLine, FaShapes, FaCode, FaLaptopCode, FaTerminal, FaGlobe, FaShoppingCart, FaMobileAlt, FaServer, FaStore } from "react-icons/fa";
import { RiVideoLine, RiFilmLine, RiBriefcaseLine } from "react-icons/ri";
import { SiCyberdefenders } from "react-icons/si";
import Image from "../assets/team/150X150.png"
import CEOImage from "../assets/team/Abdur-Rahman.jpg";
import AjmalYounusFaisal from "../assets/team/Ajmal-Younus-Faisal.jpg"
import HafijulHaqShishir from "../assets/team/Hafijul Haq Shishir.jpg"
import KajolRekha from "../assets/team/Kajol-Rekha.jpg"
import MdAbdullahIslam from "../assets/team/Md-Abdullah-Islam.jpg"
import MdEkramul from "../assets/team/Md-ekramul.jpg"
import MdBorhanUddin from "../assets/team/Md.-Borhan-Uddin.jpg"
import MohammadAbuRaihan from "../assets/team/Mohammad-Abu-Raihan.jpg"
import MujahidIslam from "../assets/team/Mujahid-Islam.jpg"
import SMTouhidulIslam from "../assets/team/S.M. Touhidul Islam.jpg"
import ShahriyaParvinRiya from "../assets/team/Shahriya-parvin-riya.jpg"
import ShiblySadik from "../assets/team/Shibly-Sadik.jpg"
import OgTalukder from "../assets/team/OG-talukder.jpg"
import Habibur from "../assets/team/Professor_Md_Habibur_Rahman.png"


export const TheNav = [
    {
        name: "Home",
        link: "#home",
    },
    {
        name: "Services",
        link: "#services",
    },
    {
        name: "Courses",
        link: "#courses",
    },
    {
        name: "About",
        link: "#about",
    },
    {
        name: "Contact",
        link: "#contact",
    },
    {
        name: "Archive",
        link: "#archive",
    }
]
export const CoursesData = [
    {
        id: 1,
        priority: 1,
        icon: FaChartLine,
        name: "Digital Marketing",
        description: "",

    },
    {
        id: 2,
        priority: 1,
        icon: FaShapes,
        name: "Graphic Design",
        description: "",
    },
    {
        id: 3,
        priority: 2,
        icon: FaCode,
        name: "Web Design & Devlopment",
        description: "",
    },
    {
        id: 4,
        priority: 2,
        icon: FaLaptopCode,
        name: "Python Programming",
        description: ""
    },
    {
        id: 5,
        priority: 1,
        icon: SiCyberdefenders,
        name: "Cyber Security",
        description: ""
    },
    {
        id: 6,
        priority: 2,
        icon: FaTerminal,
        name: "Android App Development",
        description: ""
    },
    {
        id: 7,
        priority: 2,
        icon: RiVideoLine,
        name: "Motion Graphic",
        description: ""
    },
    {
        id: 8,
        priority: 1,
        icon: RiFilmLine,
        name: "Video Editing",
        description: ""
    },
    {
        id: 9,
        priority: 2,
        icon: RiBriefcaseLine,
        name: "Office Application",
        description: ""
    },
]

export const ServicesData = [
    {
        id: 1,
        priority: 1,
        icon: FaLaptopCode,
        name: "Software Development",
        description: "",

    },
    {
        id: 2,
        priority: 1,
        icon: FaGlobe,
        name: "Web Development",
        description: "",
    },
    {
        id: 3,
        priority: 1,
        icon: FaShoppingCart,
        name: "E-commerce Devlopment",
        description: "",
    },
    {
        id: 4,
        priority: 2,
        icon: FaStore,
        name: "Woo-commerce Devlopment",
        description: ""
    },
    {
        id: 5,
        priority: 2,
        icon: FaMobileAlt,
        name: "App Devlopment",
        description: ""
    },
    {
        id: 6,
        priority: 1,
        icon: FaServer,
        name: "Hosting / Domain Services",
        description: ""
    },
]

export const users = [
    {
        name: 'Prof. Dr. M. Osman Gani Talukder ',
        avatar: OgTalukder,
        title1: "Advisor, RAM IT ",
        title2: "Former Vice Chancellor, Varendra University"
    },
    {
        name: 'Professor Md. Habibur Rahman',
        avatar: Habibur,
        title1: "Advisor, RAM IT ",
        title2: "Former Principal, Rajshahi College"
    },
];

export const ceo = [
    {
        name: 'Abdur Rahman',
        email: 'john@example.com',
        avatar: CEOImage,
        position: "CEO",
        title: "Founder"
    }
];

export const staff = [
    {
        name: 'Bricks',
        email: 'john@example.com',
        avatar: Image,
        position: "CTO",
    },
    {
        name: 'Bricks',
        email: 'john@example.com',
        avatar: Image,
        position: "CMO",
    },
];

export const trainer = [
    {
        name: 'Mohammad Abu Raihan',
        email: 'john@example.com',
        avatar: MohammadAbuRaihan,
        position: "Executive Officer"
    },
    {
        name: 'Shahriya Parvin Riya',
        email: 'john@example.com',
        avatar: ShahriyaParvinRiya,
        position: "Manager"
    },
    {
        name: 'Shibly Sadik',
        email: 'john@example.com',
        avatar: ShiblySadik,
        position: " Trainer, Graphic Design"
    },
    {
        name: 'Md Borhan Uddin',
        email: 'john@example.com',
        avatar: MdBorhanUddin,
        position: "Trainer, Digital Marketing"
    },
    {
        name: 'S. M. Touhidul Islam',
        email: 'john@example.com',
        avatar: SMTouhidulIslam,
        position: "Trainer, Digital Marketing"
    },
    {
        name: 'Md Ikramul',
        email: 'john@example.com',
        avatar: MdEkramul,
        position: "Trainer, Web Development"
    },
    {
        name: 'Ajmal Younus Faisal',
        email: 'john@example.com',
        avatar: AjmalYounusFaisal,
        position: "Trainer, Digital Marketing"
    },
    {
        name: 'Mujahid Islam',
        email: 'john@example.com',
        avatar: MujahidIslam,
        position: "Trainer, Digital Marketing"
    },
    {
        name: 'Hafijul Haq Shishir',
        email: 'john@example.com',
        avatar: HafijulHaqShishir,
        position: "Support Executive"
    },
    {
        name: 'Kajol Rekha',
        email: 'john@example.com',
        avatar: KajolRekha,
        position: "Information Officer"
    },
    {
        name: 'Md Abdullah Islam',
        email: 'john@example.com',
        avatar: MdAbdullahIslam,
        position: "Office Assistant"
    },
];

