import React, { useState, useEffect } from "react";
import Logo from "../../assets/logo/logo__200px__X__70px__removebg.png"
import { TheNav } from "../Data";

const Nav = () => {
    // const [nav, setNav] = useState(false);
    const [shadow, setShadow] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            window.scrollY >= 100 ? setShadow(true) : setShadow(false);
        });
    }, []);
    return (
        <>
            <section id="home" className="bg-[#283a5ae6] text-gray-50">
                <div
                    className={
                        shadow
                            ? "fixed w-full duration-300 z-[100] shadow-lg bg-[#283a5ae6] bg-opacity-30 backdrop-blur-sm"
                            : "fixed w-full  duration-300 z-[100] bg-[#283a5ae6] bg-opacity-90"
                    }
                >
                    <div className="container mx-auto flex flex-row justify-between items-center ">
                        <a href="/">
                            <img src={Logo} className="w-[150px] lg:w-[200px]" alt="Logo of Genesis" />
                            {/* <img src={Logo2} className="w-[60px] my-3 inline lg:hidden" alt="Logo of Genesis" /> */}
                        </a>

                        <div className="my-5 md:my-0 flex items-center ">
                            <ul className="flex flex-row items-center justify-between gap-4">
                                {TheNav?.map((items, index) => (
                                    <li className="py-2 px-3 text-md bg-transparent duration-300 -mt-2" key={index}>
                                        <a href={items.link} rel="noreferrer" >
                                            {items.name}
                                        </a>
                                    </li>
                                ))}
                                <li className="py-2 px-3 text-md bg-transparent text-[#DBA25F] rounded-lg border-2 border-[#DBA25F] duration-300 -mt-2">
                                    <a href="/en" rel="noreferrer" >
                                        EN
                                    </a>
                                    <span className="mx-2">
                                        |
                                    </span>
                                    <a href="/bn" rel="noreferrer" >
                                        BN
                                    </a>
                                </li>

                            </ul>
                        </div>

                    </div>
                </div>
            </section >
        </>
    )
}
export default Nav;