import { trainer, users, ceo, staff } from "../Data";

const UserCard = ({ user }) => {
    return (
        <div className="flex flex-col justify-center items-center bg-gray-200 p-4 m-2 w-64 text-center">
            <img src={user.avatar} alt={user.name} className="rounded-full w-[200px] h-[200px] mb-4" />
            <h2 className="text-3xl font-bold">{user?.name}</h2>
            {
                user?.title1 ?
                    <>
                        <h2 className="text-lg font-bold"> {user?.title1} </h2>
                    </>
                    :
                    <>
                    </>
            }
            {
                user?.title2 ?
                    <>
                        <h2 className="text-lg font-bold"> {user?.title2} </h2>
                    </>
                    :
                    <>
                    </>
            }
            {
                user?.title ?
                    <>
                        <h2 className="text-lg font-bold"> {user?.title} & {user?.position} </h2>
                    </>
                    :
                    <>
                        <h2 className="text-lg font-bold">{user?.position}</h2>
                    </>
            }

        </div>
    );
};

const UserList = ({ users }) => {
    return (
        <div className="flex flex-wrap justify-center">
            {users.map((user, index) => (
                <UserCard key={index} user={user} />
            ))}
        </div>
    );
};

const About = () => {

    return (
        <>
            <section id="about" className="pt-20">
                <div className="container mx-auto my-10">
                    <h1 className="text-4xl lg:text-5xl text-center font-extrabold py-8">Advisor</h1>
                    <UserList users={users} />
                </div>

                <div className="container mx-auto my-10">
                    <h1 className="text-4xl lg:text-5xl  text-center font-extrabold py-8">Team</h1>
                    <div className="text-center flex flex-wrap justify-center">
                        <h4 className="w-2/3 text-xl mb-10">
                            We led by a team who constatly questions, tinkers,
                            and challenges to unlock great creativity around every turn.
                        </h4>
                    </div>
                    <UserList users={ceo} />
                    {/* <UserList users={staff} /> */}
                    <UserList users={trainer} />
                </div>
            </section>
        </>
    )
}
export default About;