import { ServicesData } from "../Data";

const Services = () => {
    return (
        <>
            <section id="services" className="pt-20 mx-4 lg:mx-0">
                <div className="container mx-auto my-10">
                    <h1 className="text-4xl lg:text-5xl text-center font-extrabold py-8">Our Services</h1>
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-10">
                        {
                            ServicesData?.map((items, index) => (
                                <div className="rounded-3xl bg-gradient-to-tr hover:bg-gradient-to-bl from-[#283a5ae6] to-[#f7fce74d] flex flex-wrap flex-col items-center justify-center h-[300px] px-5 gap-4" key={index}>
                                    {items.icon && <items.icon className="text-5xl" />}
                                    <div className="text-2xl text-center font-bold">{items.name}</div>
                                </div>
                            ))
                        }


                    </div>
                </div>
            </section>
        </>
    )
}
export default Services;