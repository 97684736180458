
import Nav from "./components/global/Nav";
import Hero from "./components/hero/Hero";
import Services from "./components/services/Services";
import Courses from "./components/courses/Courses";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Footer from "./components/global/Footer";

function App() {
  return (
    <>
      <Nav />
      <Hero />
      <Services />
      <Courses />
      <About />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
