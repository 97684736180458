import Logo from "../../assets/logo/logo__200px__X__70px__removebg.png";
import { CoursesData, ServicesData, TheNav } from "../Data";

const Footer = () => {

    return (
        <>
            <div className="container mx-auto py-5">
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-10">
                    <div className="text-md px-3 text-center lg:text-left">
                        <img src={Logo} alt="Logo" className="mx-auto" />
                        <div>
                            H-103, Star Studio Bhabon,<br />
                            Zero Point, Ghoramara<br />
                            Boalia, Rajshahi 6100<br />
                            Bangladesh
                        </div>
                        <div>
                            <span className="font-bold">Phone:</span> +8801766358772
                        </div>
                        <div>
                            <span className="font-bold">Email:</span> info@ramitbd.com
                        </div>
                    </div>
                    <div className="text-center lg:text-left">
                        <h1 className="text-[#283a5a] font-extrabold">Useful Links</h1>
                        <div>
                            <ul className="lg:list-disc lg:pl-8">
                                {TheNav?.map((items, index) => (
                                    <li key={index}>
                                        <a href={items.link}>{items.name}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="text-center lg:text-left">
                        <h1 className="text-[#283a5a] font-extrabold">Our Services</h1>
                        <div>
                            <ul className="lg:list-disc lg:pl-8">
                                {ServicesData?.map((items, index) => (
                                    <li key={index}>
                                        {items.name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="text-center lg:text-left">
                        <h1 className="text-[#283a5a] font-extrabold">Our Courses</h1>
                        <div>
                            <ul className="lg:list-disc lg:pl-8">
                                {CoursesData?.map((items, index) => (
                                    <li key={index}>
                                        {items.name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                </div>
            </div>

            <div className="text-center text-md bg-[#283a5ae6] text-white py-2">
                &copy; All Rights Reserved By <span className="font-extrabold">RAMIT</span>.
            </div>

        </>
    )
}
export default Footer;