import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa";

const Contact = () => {
    const Email = "info@ramitbd.com";
    const Location1 = "H-103, Star Studio Bhabon, Zero Point";
    const Location2 = "Shaheb bazar, Rajshahi 6100";
    const Cell = "+8801766-358772";
    return (
        <>
            <section id="contact" className="pt-20">
                <div className="container mx-auto my-10">
                    <div className="flex flex-wrap gap-10 md:gap-0">
                        <div className="w-full lg:w-1/3 flex flex-wrap flex-col text-xl gap-10">
                            <div className="flex flex-wrap flex-row items-center justify-center md:justify-start gap-5">
                                <FaMapMarkerAlt className="text-4xl text-[#2d4876]" />
                                <div className="flex flex-row md:flex-col justify-center md:justify-start text-center md:text-left flex-wrap">
                                    <div className="text-2xl font-bold hidden lg:block">Address:</div>
                                    <div className="text-xl">
                                        <div className="">{Location1}</div>
                                        <div className="">{Location2}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-wrap flex-col md:flex-row items-center justify-center md:justify-start gap-5">
                                <FaPhoneAlt className="text-4xl text-[#2d4876]" />
                                <div className="flex flex-col justify-center md:justify-start text-center md:text-left flex-wrap">
                                    <div className="text-2xl font-bold hidden lg:block">Call:</div>
                                    <div className="text-xl">{Cell}</div>
                                </div>
                            </div>
                            <div className="flex flex-wrap flex-col md:flex-row items-center justify-center md:justify-start gap-5">
                                <FaEnvelope className="text-4xl text-[#2d4876]" />
                                <div className="flex flex-col justify-center md:justify-start text-center md:text-left flex-wrap">
                                    <div className="text-2xl font-bold hidden lg:block">Email:</div>
                                    <div className="text-xl">{Email}</div>
                                </div>
                            </div>

                        </div>

                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1817.235950506497!2d88.600521!3d24.364882!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fbefb0409beb51%3A0xf1f4a242c7eed04a!2sRAM%20IT!5e0!3m2!1sen!2sbd!4v1714889266556!5m2!1sen!2sbd"
                            allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                            className="w-full lg:w-2/3 h-[400px]"
                            title="Google Map for Ram IT By Biplob Kumar"
                        >
                        </iframe>


                    </div>
                </div>
            </section>
        </>
    )
}
export default Contact;